<template>
  <div class="data-page-content rowFlex" style="padding-bottom: 28px; justify-content: center;">
    <div class="rowFlex top">
      <div style="margin-right: 30px;">
        <div class="data-item-wrap mb-20">
          <DataItemPanelLeftBorder />
          <div class="data-item-panel">
            <div class="data-item-title" style="margin-bottom: 0;">工单数：
              <span style="color: #FFD301;font-size: 34px">{{ ticketCount }}个</span>
            </div>
            <div id="ticket-data" style="height: 266px;"></div>
          </div>
        </div>

        <div class="data-item-wrap mb-20">
          <DataItemPanelLeftBorder />
          <div class="data-item-panel">
            <div class="data-item-title">工单类型</div>
            <div id="ticket-type-data"></div>
          </div>
        </div>

        <div class="data-item-wrap">
          <DataItemPanelLeftBorder />
          <div class="data-item-panel">
            <div class="data-item-title">工单评价</div>
            <div id="ticket-evaluate-data"></div>
          </div>
        </div>
      </div>

      <div>
        <div class="data-item-panel ticket-log">
          <div class="rowFlex top">
            最新状态的工单
            <div style="margin-left: 30px">
              <div class="swiper-ticket" style="height: 106px;overflow: hidden;">
                <div class="swiper-ticket-wrapper">
                  <div class="swiper-slide swiper-no-swiping rowFlex" v-for="(ticket, ticketIndex) in ticketLogs"
                    :key="ticketIndex" style="height: 106px;align-items: baseline;"
                  >
                    <div>
                      <template v-for="(log, logIndex) in ticket.logs">
                        <p :key="log.id" v-if="logIndex <= 2">
                          <span style="margin-right: 30px">{{ formatDate(log.assignTime) }}</span>
                          <span style="margin-right: 30px">{{ ticketOrderType[ticket.type] }}</span>
                          <span class="desc">{{ getTicketLogText(log) }}</span>
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rowFlex">
          <div>
            <div class="rowFlex mb-20">
              <div style="margin-right: 30px">
                <div class="data-item-wrap mb-20">
                  <DataItemPanelLeftBorder />
                  <div class="data-item-panel">
                    <div class="data-item-title">品质检查</div>
                    <div class="rowFlex">
                      <div class="item-title">
                        <div class="item-key">报告数</div>
                        <div class="item-val">{{ inspectionReportCount }}</div>
                      </div>
                      <div class="quality-report-data" id="quality-report-data1"></div>
                      <div class="quality-report-data" id="quality-report-data2"></div>
                    </div>
                  </div>
                </div>

                <div class="data-item-wrap">
                  <DataItemPanelLeftBorder />
                  <div class="data-item-panel">
                    <div class="data-item-title">巡检情况</div>
                    <div class="rowFlex">
                      <div class="item-title">
                        <div class="item-key">任务数</div>
                        <div class="item-val">{{ inspectTaskCount }}</div>
                      </div>
                      <div id="patrol-inspection-data"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="data-item-wrap" style="flex-direction: column;">
                  <DataItemPanelLeftBorder direction="horizontal" />
                  <div class="data-item-panel">
                    <div class="data-item-title">员工考勤</div>
                    <div>
                      <div class="staff-attendance" id="staff-attendance1003"></div>
                      <div class="staff-attendance" id="staff-attendance1004"></div>
                      <div class="staff-attendance" id="staff-attendance1006"></div>
                      <div class="staff-attendance" id="staff-attendance1005"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-item-wrap">
              <DataItemPanelLeftBorder />
              <div class="data-item-panel">
                <div class="data-item-title">部门员工扣分</div>
                <div id="staff-deduction-data"></div>
              </div>
            </div>
          </div>
          <div style="margin-left: 30px">
            <div class="data-item-wrap mb-20 ">
              <DataItemPanelLeftBorder />
              <div class="data-item-panel">
                <div class="data-item-title">出入库物品</div>
                <div id="stockInAndStockOut"></div>
              </div>
            </div>

            <div class="data-item-wrap mb-20">
              <DataItemPanelLeftBorder />
              <div class="data-item-panel">
                <div class="data-item-title">验房</div>
                <div class="rowFlex energy-consumption">
                  <div class="rowFlex">
                    <div class="item-title">
                      <div class="item-key">验房次数</div>
                      <div class="item-val">{{ checkRoomCount }}</div>
                    </div>
                    <div id="inspection-room-data"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-item-wrap">
              <DataItemPanelLeftBorder />
              <div class="data-item-panel">
                <div class="data-item-title">盘点</div>
                <div class="rowFlex energy-consumption">
                  <div class="item-title">
                    <div class="item-key">盘点次数</div>
                    <div class="item-val">{{stocktakingCount}}</div>
                  </div>
                  <div id="stocktaking-data"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataItemPanelLeftBorder from "@/views/BigData/DataItemPanelLeftBorder";
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import Swiper from 'swiper';
import {
    inSevenDayEnterDataUrl,
    queryAttendanceRosterResultUrl, queryBigDataCheckRoomUrl,
    queryBigDataInspectTaskUrl, queryBigDataMeterUrl, queryBigDataStocktakingUrl, queryDepartmentScoreUrl,
    queryInspectionReportUrl, queryInspectTaskUrl, queryNewTicketUrl,
    queryTickerTypeProportionUrl,
    queryTicketEvaluateUrl,
    queryTicketStatusCountUrl,
    queryBigDataGoodsUrl,
} from "@/requestUrl";
import {DEPARTMENT_TYPE_STR, DEPARTMENT_TYPES} from "@/constant";
import {createEvaluateBarSeries, initWs} from "@/views/BigData/tools";

let charts = [];
export default {
  name: "BigDataPageThree",
  components: {
    DataItemPanelLeftBorder
  },
  data() {
    return {
      regionCode: this.$vc.getCurrentRegion().code,
      inspectionReportCount: 0,  // 品质检查报告数
      inspectTaskCount: 0, // 巡检任务总数
      meterData: {}, // 智能仪表数据
      checkRoomCount: 0,
      stocktakingCount: 0,
      newTicketInfo: {},
      ticketLogs: [],
      ticketCount: 0,
      socket: null,
      ticketOrderType: {1: '公共区域清洁', 2: '公共区域报修', 3: '物品进出', 4: '其它', 5: '投诉', 6: '气窗开启', 7: '公共区域秩序', 8: '上门维修', 9: '异常'},
      ratio: 1,
    }
  },
  mounted() {
    this.initPage();
  },
  beforeDestroy() {
      if (this.socket) {
          this.socket.close(3000, '页面关闭');
      }
    charts.forEach(chart => chart.dispose());
    charts = [];
  },
  methods: {
    async initPage() {
      if (this.socket) {
        this.socket.close()
        this.socket = null;
      }

      this.queryInspectTask();
      this.queryMeterData();

      Promise.all([
        this.queryTickerTypeProportion(),
        this.queryTicketEvaluate(),
        this.queryInspectionReport(),
        this.queryAttendanceRosterResult(),
        this.queryDepartmentScore(),
        this.queryCheckInRoom(),
        this.queryTicketStatusCount(),
        this.queryStocktaking(),
        this.getStockInAndStockOut(),
        //  -----------------
        this.queryNewTicketInfo(),
      ])
      .then(allRes => {
        const [
          ticketTypeChart, ticketEvaluateChart, inspectionReportCharts,
          attendanceCharts, departmentScoreChart, checkinRoomChart,
          ticketStatusChart, stocktakingChart, stockInAndStockOutChart,
        ] = allRes;
        const defaultOptions = {
          direction: 'vertical',
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: {
            delay: 1000,
          },
          /*loop: true,
          observer: true,
          observeParents: true,*/
          wrapperClass: 'swiper-ticket-wrapper',
        };
        const ticketSwiper = this.initSwiper('.swiper-ticket', defaultOptions);
        this.socket = initWs({
          messageHandler: res => {
            try {
              let data = JSON.parse(res.data);
              const dataTypeHandlers = {
                stocktaking: () => {
                  stocktakingChart.setOption({
                    series: [
                      {
                        data: [
                          {value: data.data.normal || 0, name: '盘点正常'},
                          {value: data.data.noNormal || 0, name: '盘点异常'},
                        ]
                      }
                    ]
                  })
                },
                queryNewTicket: () => {
                  this.ticketLogs.splice(this.ticketLogs.length - 1, 0, ...data.data.datas);
                  ticketSwiper.updateSlides()
                  for(let i= 0; i < data.data.datas.length; i++) {
                    ticketSwiper.slideNext();
                  }
                },
                ticketType: () => {
                  const {clean, warranty, goods, other, complain, windowOpen, cargoLadder, homeRepair, anAbnormality} = data.data;
                  ticketTypeChart.setOption({
                    series: [{
                      data: [
                        {name: '公共区域清洁', value: clean || 0},
                        {name: '公共区域报修', value: warranty || 0},
                        {name: '气窗开启', value: windowOpen || 0},
                        {name: '公共区域秩序', value: cargoLadder || 0},
                        {name: '物品进出', value: goods || 0},
                        {name: '上门维修', value: homeRepair || 0},
                        {name: '投诉', value: complain || 0},
                        {name: '异常', value: anAbnormality || 0},
                        {name: '其它', value: other || 0}
                      ],
                    }]
                  })
                },
                TicketAppraise: () => {
                  ticketEvaluateChart.setOption({
                    series: createEvaluateBarSeries(data.data, {
                      'clean': 0,
                      'warranty': 1,
                      'goods': 2,
                      'other': 3,
                      'complain': 4,
                      'windowOpen': 5,
                      'cargoLadder': 6,
                      'homeRepair': 7,
                      'anAbnormality': 8,
                    })
                  })
                },
                check: () => {
                  const {checkin, checkout, checkElse, count} = data.data;
                  checkinRoomChart.setOption({
                    series: [{
                      data: [
                        {value: checkin, name: '入驻验房'},
                        {value: checkout, name: '退租验房'},
                        {value: checkElse, name: '其它验房'},
                      ],
                    }]
                  })
                },
                meter: () => {
                  this.meterData = data.data;
                },
                Attendance: () => {
                  data.data.forEach(({dutyType, normal: value}) => {
                    attendanceCharts[dutyType].setOption({
                      series: [{
                        data: [
                          {
                            value,
                            name: '正常',
                            label: {
                              show: true,
                              position: 'center',
                              formatter: e => {
                                if (e.data.value != -1) {
                                  return `正常:${e.data.value}%`;
                                }
                                return '无排班';
                              },
                              color: '#fff',
                              fontSize: 13,
                              fontWeight: 'bold',
                            }
                          },
                          {value: 100 - value},
                        ],
                      }]
                    })
                  })
                },
                score: () => {
                  let dutyData = {};
                  data.data.forEach(({name, score}) => {
                    dutyData[name] = score;
                  })
                  departmentScoreChart.setOption({
                    series: [{
                      data: [
                        {name: '工程部', value: dutyData[DEPARTMENT_TYPES.ENGINEERING]},
                        {name: '客服部', value: dutyData[DEPARTMENT_TYPES.CUSTOMER_SERVICE]},
                        {name: '保安部', value: dutyData[DEPARTMENT_TYPES.SECURITY]},
                        {name: '保洁部', value: dutyData[DEPARTMENT_TYPES.PROCTER_GAMBLE]}
                      ]
                    }]
                  })
                },
                inspectionReport: () => {
                  inspectionReportCharts[0].setOption({
                    series: [{
                      data: [
                        {value: data.data.checkResult, name: '合格率'},
                        {value: 100 - data.data.checkResult, name: '不合格率'},
                      ],
                    }]
                  })
                  inspectionReportCharts[1].setOption({
                    series: [{
                      data: [
                        {value: data.data.isFinish, name: '未整改'},
                        {value: 100 - data.data.isFinish, name: '已整改'},
                      ],
                    }]
                  })
                },
                TicketByState: () => {
                  this.ticketCount = data.data.count;
                  ticketStatusChart.setOption({
                    series: [
                      {
                        data: [
                          {
                            value: 100 - data.data.overFinished, name: '',
                            selected: false,
                            itemStyle: {
                              borderRadius: 0,
                            },
                          },
                          {
                            value: data.data.overFinished, name: '超时处理',
                            label: {
                              show: true,
                              // position: 'center',
                              color: 'rgba(242, 64, 101, 1)',
                              fontSize: 30,
                              formatter: '{d}%'
                            },
                            labelLine: {
                              show: true,
                            },
                          },
                        ]
                      },
                      {
                        data: [
                          {
                            value: data.data.finished,
                            name: '已处理',
                            labelLine: {
                              show: true,
                            },
                            label: {
                              color: 'rgba(31, 198, 255, 1)',
                              formatter: '{d}%',
                              borderColor: '#8C8D8E',
                              fontSize: 30,
                            },
                          },
                          {
                            value: 100 - data.data.finished,
                            name: '',
                            itemStyle: {
                              borderRadius: 0,
                            },
                            selected: false,
                          },
                        ]
                      }
                    ]
                  })
                },
                bigDataGoods: () => {
                    let NameList = data.data.map(item => {
                        return item.name;
                    })
                    let storeList = data.data.map(item => {
                        return item.store;
                    })
                    let deliverList = data.data.map(item => {
                        return item.deliver;
                    })
                    stockInAndStockOutChart.setOption({
                        xAxis: [
                            {
                                type: 'category',
                                data: NameList,
                                axisPointer: {
                                    type: 'shadow'
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                min: 0,
                                max: storeList[0] > deliverList[0] ? storeList[0] : deliverList[0],
                                interval: (storeList[0] > deliverList[0] ? storeList[0] : deliverList[0]) / 5,
                                axisLabel: {
                                    formatter: '{value}',
                                    color: "#fff"
                                }
                            }
                        ],
                        series: [
                            {
                                name: '入库',
                                type: 'bar',
                                data: storeList
                            },
                            {
                                name: '出库',
                                type: 'bar',
                                data: deliverList
                            }
                        ]
                    })
                }
              }
              try {
                dataTypeHandlers[data.method]();
                console.log(data.method, '可执行');
              } catch (err) {
                console.log(data.method, '报错', err)
              }
            } catch(err) {
              console.log(err, '解析失败')
            }
          }
        });
      })
    },
    getTicketLogText(log) {
      let text = '';
      switch(log.state) {
        case 1:
          text = `${log.assigner || '系统'}分配给${log.assignee}`;
          break;
        case 2:
          text = `${log.assigner}转单给${log.assignee}`;
          break;
        case 3:
          text = `${log.assigner || log.assignee}申请暂缓`;
          break;
        case 4:
          text = `${log.assigner || log.assignee}同意暂缓`;
          break;
        case 5:
          text = `${log.assigner || log.assignee}驳回暂缓`;
          break;
        case 6:
          text = `${log.assigner || log.assignee}反馈进度`;
          break;
        case 7:
          text = `${log.assigner || log.assignee}已处理`;
          break;
        case 8:
          text = `${log.assigner || log.assignee}反馈结果`;
          break;
        case 9:
          if (log.assigner === '系统') {
            text = `${log.assigner || log.assignee}已分配给${log.assignee}`;
          } else {
            text = `${log.assigner || log.assignee}跟进处理`;
          }
          break;
        case 10:
          text = `${log.assigner || log.assignee}审批拒绝`;
          break;
        case 11:
          text = `${log.assigner || log.assignee}审批同意`;
          break;
      }
      return text;
    },
    formatDate(timeStamp) {
      if (!timeStamp) {
        return false;
      }
      return dayjs(timeStamp).format('YYYY.MM.DD HH:mm');
    },
    // 查询最新工单
    queryNewTicketInfo() {
      return this.$fly.get(queryNewTicketUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return false;
        }
        res.data.datas[0].logs = res.data.datas[0].logs.slice(0, 3);
        if (this.ticketLogs.length === 0) {
          this.ticketLogs = [res.data.datas[0], res.data.datas[0]];
          return false;
        }
        return false;
      })
    },
    // 查询盘点数据
    queryStocktaking() {
      return this.$fly.get(queryBigDataStocktakingUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        const {count, normal, noNormal} = res.data;
        this.stocktakingCount = count;
        // 盘点
        return this.initEChart('stocktaking-data', {
          color: ['rgba(0, 86, 255, 0.85)', 'rgba(238, 128, 48, 0.85)'],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 0,
            textStyle: {
              color: '#fff'
            },
            itemWidth: 9,
            itemHeight: 9,
          },
          series: [
            {
              name: '盘点',
              type: 'pie',
              radius: '65%',
              label: {
                position: 'inside',
                formatter: '{d}%',
                color: '#fff',
              },
              data: [
                {value: normal || 0, name: '盘点正常'},
                {value: noNormal || 0, name: '盘点异常'},
              ],
            }
          ]
        })
      })
    },
    // 查询验房数据
    queryCheckInRoom() {
      return this.$fly.get(queryBigDataCheckRoomUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        const {checkin, checkout, checkElse, count} = res.data;
        this.checkRoomCount = count;
        // 验房
        return this.initEChart('inspection-room-data', {
          color: ['rgba(0, 214, 207, 1)', 'rgba(238, 81, 48, 1)', 'rgb(238,168,48)'],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 0,
            textStyle: {
              color: '#fff',
              fontSize: 16,
            },
            itemWidth: 9,
            itemHeight: 9,

          },
          series: [
            {
              name: '验房次数',
              type: 'pie',
              radius: '65%',
              label: {
                position: 'inside',
                formatter: '{d}%',
                color: '#fff'
              },
              data: [
                {value: checkin, name: '入驻验房'},
                {value: checkout, name: '退租验房'},
                {value: checkElse, name: '其它验房'},
              ],
            }
          ]
        })
      })
      .catch(() => {
          const [checkin, checkout, checkElse, count] = [0, 0, 0, 0];
          this.checkRoomCount = count;
          // 验房
          return this.initEChart('inspection-room-data', {
              color: ['rgba(0, 214, 207, 1)', 'rgba(238, 81, 48, 1)', 'rgb(238,168,48)'],
              tooltip: {
                  trigger: 'item'
              },
              legend: {
                  top: 0,
                  textStyle: {
                      color: '#fff',
                      fontSize: 16,
                  },
                  itemWidth: 9,
                  itemHeight: 9,

              },
              series: [
                  {
                      name: '验房次数',
                      type: 'pie',
                      radius: '65%',
                      label: {
                          position: 'inside',
                          formatter: '{d}%',
                          color: '#fff'
                      },
                      data: [
                          {value: checkin, name: '入驻验房'},
                          {value: checkout, name: '退租验房'},
                          {value: checkElse, name: '其它验房'},
                      ],
                  }
              ]
          })
      })
    },
    // 获取抄表数据
    queryMeterData() {
      this.$fly.get(queryBigDataMeterUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        this.meterData = res.data;
      })
    },
    // 员工扣分
    queryDepartmentScore() {
      return this.$fly.get(queryDepartmentScoreUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        let dutyData = {};
        res.data.forEach(({name, score}) => {
          dutyData[name] = score;
        })
        // 部门员工扣分
        return this.initEChart('staff-deduction-data', {
          color: ['rgba(91, 143, 249, 1)'],
          grid: {
            top: 0,
            left: 0,
            containLabel: true
          },
          height: '100%',
          xAxis: {axisLine: {lineStyle: {color: '#fff'}}, minInterval: 1},
          yAxis: {
            type: 'category',
            axisLine: {lineStyle: {color: '#fff'}},
            data: ['工程部', '客服部', '保安部', '保洁部']
          },
          series: [
            {
              type: 'bar',
              label: {
                show: true,
                position: 'inside',
                fontWeight: 'bold',
                color: '#fff',
                formatter: (e) => {
                  if (e.data.value !== 0) {
                    return e.data.value;
                  }
                  return '';
                }
              },
              data: [
                {name: '工程部', value: dutyData[DEPARTMENT_TYPES.ENGINEERING]},
                {name: '客服部', value: dutyData[DEPARTMENT_TYPES.CUSTOMER_SERVICE]},
                {name: '保安部 ', value: dutyData[DEPARTMENT_TYPES.SECURITY]},
                {name: '保洁部', value: dutyData[DEPARTMENT_TYPES.PROCTER_GAMBLE]}
              ]
            }
          ]
        })
      })
    },
    /**
     * 员工考勤图表配置项
     * @param {Number} dutyType 部门类型
     * @param {Number} value 正常考勤百分比
     * */
    createStaffAttendanceEChartOptions(dutyType, value) {
      let dutyTypeColor = {
        1003: ['rgba(33, 212, 125, 1)', 'rgba(246, 189, 22, 1)'],
        1004: ['rgba(0, 136, 255, 1)', 'rgba(116, 78, 207, 1)'],
        1005: ['rgba(255, 207, 77, 1)', 'rgba(30, 136, 253, 1)'],
        1006: ['rgba(255, 90, 90, 1)', 'rgba(235, 169, 15, 1)'],
      };
      return {
        title: [
          {text: DEPARTMENT_TYPE_STR[dutyType], textStyle: {color: '#fff'}, left: 0, top: 'middle'},
        ],
        color: dutyTypeColor[dutyType],
        series: [
          {
            name: DEPARTMENT_TYPE_STR[dutyType],
            type: 'pie',
            radius: '80%',
            label: {
              show: false,
            },
            right: 0,
            width: '100%',
            height: '100%',
            data: [
              {
                value,
                name: '正常',
                label: {
                  show: true,
                  position: 'center',
                  formatter: e => {
                    if (e.data.value != -1) {
                      return `正常:${e.data.value}%`;
                    }
                    return '无排班';
                  },
                  color: '#fff',
                  fontSize: 13,
                  fontWeight: 'bold',
                }
              },
              {value: 100 - value},
            ],
          },
        ]
      }
    },
    // 员工考勤
    queryAttendanceRosterResult() {
      return this.$fly.get(queryAttendanceRosterResultUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        const charts = {};
        // 员工考勤
        res.data.forEach(({dutyType, normal: val}) => {
          charts[dutyType] = this.initEChart(`staff-attendance${dutyType}`,  this.createStaffAttendanceEChartOptions(dutyType, val))
        })
        return charts;
      })
    },
    //巡检情况(巡检总数、异常占总数百分比)
    queryInspectTask() {
      this.$fly.get(queryBigDataInspectTaskUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        this.inspectTaskCount = res.data.count;
        // 巡检
        this.initEChart('patrol-inspection-data',  {
          color: ['rgba(161, 91, 249, 1)', 'rgba(61, 117, 255, 1)'],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 0,
            textStyle: {
              color: '#fff'
            },
            itemWidth: 9,
            itemHeight: 9,
          },
          series: [
            {
              name: '巡检情况',
              type: 'pie',
              radius: '70%',
              label: {
                position: 'inside',
                formatter: '{d}%',
                color: '#fff',
              },
              data: [
                {value: res.data.percentage, name: '异常率'},
                {value: 100 - res.data.percentage, name: '正常率'},
              ],
            }
          ]
        })
      })
    },
    // 品质检查
    queryInspectionReport() {
      return this.$fly.get(queryInspectionReportUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return;
        }
        this.inspectionReportCount = res.data.count;
        const defaultOptions = {
          title: [{text: '检查结果', textStyle: {color: '#fff'}, top: 0, left : 'center',}],
          color: ['rgba(91, 143, 249, 1)', 'rgba(90, 216, 166, 1)'],
          tooltip: {
            trigger: 'item',
          },
          legend: {
            bottom: 0,
            textStyle: {
              color: '#fff'
            },
            itemWidth: 9,
            itemHeight: 9,
          },
          series: [
            {
              name: '检查结果',
              type: 'pie',
              radius: '70%',
              label: {
                position: 'inside',
                formatter: '{d}%',
                color: '#fff',
              },
              left: 0,
              width: '100%',
              data: [
                {value: res.data.checkResult, name: '合格率'},
                {value: 100 - res.data.checkResult, name: '不合格率'},
              ],
            },

          ],
        };
        // 品质报告
        return [
          this.initEChart('quality-report-data1',  defaultOptions),,
          this.initEChart('quality-report-data2',  {
            ...defaultOptions,
            title: [{text: '整改情况', textStyle: {color: '#fff'}, top: 0, right: 'center'}],
            series: [
              {
                name: '整改情况',
                type: 'pie',
                radius: '70%',
                right: 0,
                width: '100%',
                label: {
                  position: 'inside',
                  formatter: '{d}%',
                  color: '#fff',
                },
                data: [
                  {value: res.data.isFinish, name: '未整改'},
                  {value: 100 - res.data.isFinish, name: '已整改'},
                ],
              }
            ]
          })
        ]
      })
    },
      // 工单评价
      queryTicketEvaluate() {
          return this.$fly.get(queryTicketEvaluateUrl, {
              regionCode: this.regionCode,
          })
              .then(res => {
                  if (res.code !== 0) {
                      return ;
                  }
                  const legendNames = ['未评价', '一星', '二星', '三星', '四星', '五星'];

                  // 评价
                  return this.initEChart('ticket-evaluate-data', {
                      color: ['#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#9a60b4'],
                      legend: {
                          data: legendNames,
                          textStyle: {color: '#fff'}
                      },
                      grid: {
                          left: 0,
                          bottom: 0,
                          containLabel: true,
                      },
                      xAxis: [
                          {
                              type: 'category',
                              data: ['清洁', '报修', '物品进出', '其它', '投诉', '气窗开启', '公共区域秩序', '上门维修'],
                              axisLine: {
                                  lineStyle: {color: '#fff'},
                              },
                              axisLabel: {
                                  interval: 0,
                                  rotate: 40,
                              }
                          }
                      ],
                      yAxis: [
                          {
                              type: 'value',
                              minInterval: 10,
                              max: 100,
                              axisLine: {
                                  lineStyle: {color: '#fff'}
                              },
                              axisLabel: {
                                  formatter: '{value}%'
                              }
                          }
                      ],
                      series: createEvaluateBarSeries(res.data, {
                          'clean': 0,
                          'warranty': 1,
                          'goods': 2,
                          'other': 3,
                          'complain': 4,
                          'windowOpen': 5,
                          'cargoLadder': 6,
                          'homeRepair': 7,
                          // 'anAbnormality': 8,
                      }),
                  })
              })
      },
    // 工单类型
    queryTickerTypeProportion() {
      return this.$fly.get(queryTickerTypeProportionUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        const {clean, warranty, goods, other, complain, windowOpen, cargoLadder, homeRepair, anAbnormality} = res.data;
        // 工单类型
        return this.initEChart('ticket-type-data', {
          color: [
            'rgb(255,90,90)',
            'rgba(41, 216, 180, 1)',
            'rgba(235, 169, 15, 1)',
            'rgba(155, 119, 239, 1)',
            'rgba(42, 194, 59, 1)',
            'rgba(30, 196, 255, 1)',
            'rgba(231, 215, 21, 1)',
            'rgba(161, 36, 255, 1)',
            'rgb(13,0,255)',
          ],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            left: 0,
            top: 'middle',
            orient: 'vertical',
            itemGap: 20,
            itemWidth: 9,
            itemHeight: 9,
            itemStyle: {
              borderWidth: 0,
            },
            textStyle: {
              color: '#fff'
            },
          },
          series: [
            {
              name: '工单类型',
              type: 'pie',
              right: 0,
              width: 650,
              radius: ['60%', '90%'],
              itemStyle: {
                borderRadius: 2,
                borderColor: '#fff',
                borderWidth: 1,
                borderCap: 'round',
              },
              label: {
                position: 'inner',
                fontSize: 12,
                fontWeight: 'bold',
                formatter: e => e.data.value > 0 ? e.data.value + '%' : '',
                color: '#fff',
              },
              data: [
                {name: '公共区域清洁', value: clean || 0},
                {name: '公共区域报修', value: warranty || 0},
                {name: '气窗开启', value: windowOpen || 0},
                {name: '公共区域秩序', value: cargoLadder || 0},
                {name: '物品进出', value: goods || 0},
                {name: '上门维修', value: homeRepair || 0},
                {name: '投诉', value: complain || 0},
                // {name: '异常', value: anAbnormality || 0},
                {name: '其它', value: other || 0}
              ],
            }
          ]
        })
      })
    },
    // 工单数
    queryTicketStatusCount() {
      return this.$fly.get(queryTicketStatusCountUrl, {
        regionCode: this.regionCode,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        this.ticketCount = res.data.count;
        // 工单数
        return this.initEChart('ticket-data', {
          legend: {
            bottom: 0,
            textStyle: {
              color: '#fff',
              fontSize: 16,
            },
            itemGap: 50,
            itemWidth: 20,
            itemHeight: 6,
          },
          series: [
            {
              type: 'pie',
              selectedMode: 'single',
              color: ['rgba(39, 67, 121, 1)', 'rgba(242, 64, 101, 1)'],
              radius: ["46%", '52%'],
              labelLine: {
                show: false,
              },
              zlevel: 5,
              itemStyle: {
                borderRadius: 12,
              },
              data: [
                {
                  value: 100 - res.data.overFinished, name: '',
                  selected: false,
                  itemStyle: {
                    borderRadius: 0,
                  },
                },
                {
                  value: res.data.overFinished, name: '超时处理',
                  label: {
                    show: true,
                    // position: 'center',
                    color: 'rgba(242, 64, 101, 1)',
                    fontSize: 30,
                    formatter: '{d}%'
                  },
                  labelLine: {
                    show: true,
                  },
                },
              ]
            },
            {
              name: '已处理数量',
              type: 'pie',
              labelLine: {
                show: false,
              },
              itemStyle: {
                borderRadius: 12,
              },
              radius: ['58%', '64%'],
              color: ['rgba(31, 198, 255, 1)', 'rgba(39, 67, 121, 1)'],
              data: [
                {
                  value: res.data.finished,
                  name: '已处理',
                  labelLine: {
                    show: true,
                  },
                  label: {
                    color: 'rgba(31, 198, 255, 1)',
                    formatter: '{d}%',
                    borderColor: '#8C8D8E',
                    fontSize: 30,
                  },
                },
                {
                  value: 100 - res.data.finished,
                  name: '',
                  itemStyle: {
                    borderRadius: 0,
                  },
                  selected: false,
                },
              ]
            }
          ]
        })
      })
    },
    // 获取出入库
    getStockInAndStockOut() {
        return this.$fly.get(queryBigDataGoodsUrl,{
            regionCode: this.regionCode,
        })
        .then(res => {
            if (res.code != 0) {
                return
            }
            let NameList = res.data.map(item => {
                return item.name;
            })
            let storeList = res.data.map(item => {
                return item.store;
            })
            let deliverList = res.data.map(item => {
                return item.deliver;
            })
            console.log(storeList, 444444)
            console.log(deliverList, 444444)
            return this.initEChart('stockInAndStockOut', {
                color: ['#5AAEF3', '#62D9AD'],
                grid: {
                    top: 30,
                    left: 40,
                    right: 20,
                    bottom: 20,
                },
                legend: {
                    data: ['入库', '出库'],
                    textStyle: {
                        color: '#fff'
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        data: NameList,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#fff'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            color: "#fff"
                        }
                    }
                ],
                series: [
                    {
                        name: '入库',
                        type: 'bar',
                        data: storeList
                    },
                    {
                        name: '出库',
                        type: 'bar',
                        data: deliverList
                    }
                ]
            })
        })
    },
    /**
     * 初始化echart
     * @param {String} elName 展示echart图标的元素id
     * @param {Object} options echart配置项
     * @param {Boolean} loopAnimation 是否循环显示动画
     * */
    initEChart(elName, options, loopAnimation = false) {
      const myChart = echarts.init(document.getElementById(elName));
      myChart.setOption(options);
      if (loopAnimation) {
        setInterval(() => {
          myChart.clear();
          myChart.setOption(options);
        }, 4000)
      }
      charts.push(myChart);
      return myChart;
    },
    initSwiper(elName, options = {}) {
      const tempSwiper = new Swiper(elName, options);
      return tempSwiper;
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "./common.styl"

  .item-title
    border-right 1px solid #1AC9FF
    padding 34px
    display flex
    flex-flow column nowrap
    justify-content center
  .item-key
    font-size 24px
    line-height 1
    text-align center
    white-space nowrap
    color rgba(25, 135, 241, 1)
  .item-val
    font-size 34px
    //line-height 1
    text-align center
    color rgba(255, 211, 1, 1)
  .energy-consumption
    justify-content space-around
    width 440px
    img
      height 3.54vw
      display block
      margin 0 auto
  #ticket-data
    width 460px
    height 246px
  #ticket-type-data, #ticket-evaluate-data
    width 460px
    height 196px
  .ticket-log
    width 1300px
    border-right none
    //height 8.85vw
    margin-bottom 20px
    font-size 24px
    text-align left
    height 152px
    overflow hidden
    & > div
      position relative
      padding-left 18px
      align-items baseline
      line-height 1
      p
        margin-bottom 0
        @extends .rowFlex
        &:not(:last-child)
          margin-bottom 10px
      span
        display inline-block
        &.desc
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
      &::after
        position absolute
        content " "
        left 0
        top 0
        width 8px
        height 24px
        background rgba(30, 196, 255, 1)
  #inspection-room-data
    width 270px
    height 156px
  #stocktaking-data
    width 270px
    height 166px
  #staff-deduction-data
    width 730px
    height 146px
  #patrol-inspection-data
    width 280px
    height 156px
  #stockInAndStockOut
    width 440px
    height 156px
  .quality-report-data
    width 140px
    height 176px
  .staff-attendance
    width 240px
    height 106px
</style>
